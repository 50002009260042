<!--
*************************************************************************************
Page Heading and Buttons
*************************************************************************************
-->
<div id="manageListsDiv" class="content" role="main">
  <div class="content table-search-row second-header-fixed">
    <table class="text-wrapper">
      <tr>
        <td>
          <label id="lblPageHeader">Lists</label>
          <span class="p-input-icon-left p-float-label">
            <input [(ngModel)]="searchKeyword" class="search-input rounded-pill" pInputText type="text"
              (keyup.enter)="onApplyGlobalFilter()" (input)="onGlobalFilterChange()" placeholder="Search by keyword" />
          </span>
          <span type="button" pButton pRipple icon="fal fa-search search-fa" pTooltip="Execute Search"
            tooltipPosition="bottom" showDelay="500" (click)="onApplyGlobalFilter()"
            class="p-button-secondary execute-search" style="margin-bottom: 10px">
          </span>
          <div class="input-group search-field position-relative p-ml-4"></div>
        </td>
        <td align="right">
          <p-button icon="pi pi-plus text-white" label="Create New List" [disabled]="disableCreateNew"
            styleClass="header-button" (onClick)="CreateNewList()"></p-button>
        </td>
      </tr>
    </table>
  </div>
</div>

<!--
*************************************************************************************
Peoples & Loans All List Cards
*************************************************************************************
-->

<div class="text-wrapper grid-section mb-8">
  <ng-container>
    <div class="cardGrid wrapper" style="max-width: auto; width: 100%" *ngIf="allList">
      <p-dataView #PeopleLoanDataView [value]="allList" [paginator]="webAPI.paging.totalRecords > 0" [rows]="12"
        [rowsPerPageOptions]="[12, 24, 48]" [loading]="loadingPage" [lazy]="true" (onLazyLoad)="onPageChanged($event)"
        [totalRecords]="webAPI.paging.totalRecords" filterBy="title" [sortField]="sortField" [sortOrder]="sortOrder"
        layout="grid" scrollWidth="100%" scrollHeight="150px;" emptyMessage=" ">
        <ng-template pTemplate="gridItem" let-row let-record let-rowIndex="rowIndex" let-columns="columns">
          <div class="col-3 md:col-3">
            <div class="card main-card">
              <div class="cardName card_header" style="
                  cursor: pointer;
                  padding-top: 10px;
                  background-color: #f4edfe;
                " (click)="viewListClick(record)">
                <span>{{ record.title }}</span>
              </div>

              <div class="cardName card_body" style="padding-top: 10px">
                <!-- [pTooltip]="parsedText(record.displayHtml)"> -->
                <!-- <div style="color: #7F7F7F">{{record.displayHtml}}</div>  -->
                <div style="color: #7f7f7f" [innerHTML]="record.displayHtml"></div>
              </div>
              <div class="cardActions card_bottom flex-row p-2">
                <div class="ml-0 mr-auto flex flex-column justify-content-center">
                  <p-button (click)="viewListClick(record)" styleClass="p-button-secondary" label="View List"
                    [style]="{ cursor: 'pointer', 'margin-left': '0.5px' }"></p-button>
                </div>
                

                <div class="ml-auto mr-0 flex flex-column justify-content-center" *ngIf="record.canDelete"> 
                  <i style="color: #aab4bf; cursor: pointer" class="pi pi-trash" (click)="deleteListClick(record)"></i>
                </div>

                <div class="ml-1 mr-0">
                  <ng-container *ngIf="record.ownedByAduvo; else showNotOwnedByAduvoPicture">
                    <img src="assets/images/logo-aduvo.png" pTooltip="Owned By Aduvo" tooltipPosition="bottom" alt="Aduvo" class="img-fluid rounded-circle" width="40">
                  </ng-container>
                  <ng-template #showNotOwnedByAduvoPicture>
                    <ng-container *ngIf="record.picture !== null; else showInitials">
                      <ng-container *ngIf="record?.ownerFirstName != null && record?.ownerLastName != null; else useNotNameToolTip">
                        <img src="{{record.picture}}" pTooltip="Owned By {{record?.ownerFirstName}} {{record?.ownerLastName}}" 
                          tooltipPosition="bottom" alt="{{record?.ownerFirstName}} {{record?.ownerLastName}}" class="img-fluid rounded-circle mr-0 ml-1" width="40">
                      </ng-container>
                      <ng-template #useNotNameToolTip>
                        <img src="{{record.picture}}"  
                          tooltipPosition="bottom" alt="User Profile Image" class="img-fluid rounded-circle mr-0 ml-1" width="40">
                      </ng-template>
                    </ng-container>
                    <ng-template #showInitials>
                      <div class="mr-0 ml-1">
                        <ng-container *ngIf="record?.ownerFirstName != null && record?.ownerLastName != null;else showNotAvailable">
                          <p-avatar shape="circle" class="content-card-avatar img-fluid rounded-circle me-1"
                            pTooltip="Owned By {{record?.ownerFirstName}} {{record?.ownerLastName}}"
                            tooltipPosition="bottom"
                            [label]="record?.ownerFirstName[0] + record?.ownerLastName[0]">
                          </p-avatar>
                        </ng-container>
                        <ng-template #showNotAvailable>
                          <p-avatar shape="circle" class="content-card-avatar img-fluid rounded-circle me-1"
                            label="N/A">
                          </p-avatar>
                        </ng-template>
                      </div>
                    </ng-template>
                  </ng-template>
                </div>

              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="paginatorleft" let-state>
          <label style="font-weight: 400">{{ webAPI.paging.totalRecords }}
            {{ webAPI.paging.totalRecords > 1 ? "results" : "result" }}</label>
        </ng-template>
        <ng-template pTemplate="paginatorright" let-state>
          <div>
            <label style="margin-top: 1px; margin-right: 8px; font-weight: 400">Go to Page</label>
            <input type="text" pInputText pKeyFilter="int" [(ngModel)]="webAPI.paging.currentPage" width="3"
              maxlength="3" style="
                text-align: center;
                width: 4rem;
                margin-right: 8px;
                border-radius: 20px;
              " (change)="onGotoPage($event)" />
          </div>
        </ng-template>
        <!-- <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8">No records found</td>
          </tr>
        </ng-template> -->
      </p-dataView>
      <div *ngIf="noRecordFound">
        <span>No records found</span>
      </div>
    </div>
  </ng-container>
</div>

<!--
*************************************************************************************
Create New List Dialogs
*************************************************************************************
-->

<!-- <p-confirmDialog header="Warning" icon="pi pi-exclamation-triangle" [baseZIndex]="11000" ></p-confirmDialog> -->
<!--  <p-confirmPopup></p-confirmPopup>  -->
<!-- attribute not working 'height':'70% !important' 'min-height':'750px !important', -->
<p-dialog class="custom-dialog" id="change_email" #createListDialog [(visible)]="showCreateNewDialog"
  [resizable]="false" [modal]="true"
  [style]="{ 'font-weight': '700', 'max-width' : '98%', 'min-width' : '98%', 'max-height' : '99%',  color: 'red' }"
  (onHide)="cancelCreateNewList()">
  <p-header>
    <span class="dialog-head">Create New List</span>
  </p-header>

  <div *ngIf="showCustomMsg" style="background-color: lightpink; position: relative">
    <div class="card rounded-1 editor-card-header-text message-pop-up-header" style="background-color: #ffeded">
      <div class="d-flex justify-content-between align-items-center px-3 py-2" style="background-color: #ffeded">
        <span class="message-pop-up" style="color: #fc5151">! You made changes to an Aduvo list, please save your new
          custom
          list.</span>
      </div>
    </div>
  </div>

  <div class="modal-content" id="modalDivPanel">
    <div class="mb-2 row" style="padding-left: 10px; margin-bottom: 15px !important">
      <label class="col-md-12 pl-0 col-form-label" style="font-size: 18px; color: #2e2e2e; line-height: 18px">List
        Name</label>
      <div class="col-md-4 pl-0">
        <input id="newListName" class="form-control" style="font-size: 16px; line-height: 24px" maxlength="100"
          [(ngModel)]="newListName" (input)="listNameChange()" />
      </div>
    </div>
  </div>
  <div *ngIf="listNameExists" style="text-align: left; font-weight: 500; color: red">
    List name already exists
  </div>
  <div *ngIf="listNameRequired" style="text-align: left; font-weight: 500; color: red">
    List name is required
  </div>
  <!-- to force cleaningup of people-and-list-filter comp -->
  <div *ngIf="showCreateNewDialog" id="divBody" style="
      padding-right: 7rem;
      background-color: #F4EDFE;
      padding: 0 10px;
      /* max-height: 100px !important; */
    ">
    <!-- <people-and-list-filter
      (onFilterCreatedEvent)="onFilterCreatedEvent($event)"
    ></people-and-list-filter> -->
    <app-people-and-list-advanced-filter
      (onFilterCreatedEvent)="onFilterCreatedEvent($event)"></app-people-and-list-advanced-filter>
  </div>

  <!--
  *************************************************************************************
  List Table
  *************************************************************************************
  -->
  <div class="text-wrapper py-3 mb-5 modaldata-grid" *ngIf="createList" style="min-height: 600px;">
    <p-table #CreateListTableId class="pulseTable" [value]="createList" [lazy]="true"
      (onLazyLoad)="onCreateListPageChanged($event)" [rows]="25" [rowsPerPageOptions]="[25, 50, 100]"
      [paginator]="createListWebAPI.paging.totalRecords > 0" [totalRecords]="createListWebAPI.paging.totalRecords"
      [loading]="loadingPage" [scrollable]="false" scrollWidth="100%" scrollHeight="100%" scrollDirection="both"
      [resizableColumns]="true" [style]="{ width: '100%' }" [virtualScroll]="false" sortField="name" [sortOrder]="1"
      [showCurrentPageReport]="true" [(first)]="pageIndex">
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th pSortableColumn="name">
            Name <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="masterContactTypeName">
            Type <p-sortIcon field="userType"></p-sortIcon>
          </th>
          <th>Email</th>
          <th>Mobile</th>
          <th>Work</th>
          <th>Home</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-record let-rowIndex="rowIndex" let-columns="columns">
        <tr>
          <td></td>
          <td class="title-column">
            <span class="userGridName"><a style="color: var(--primary-color-pulse)">{{
                record.name
                }}</a></span>
          </td>
          <td class="p-body-colum">{{ record.masterContactTypeName }}</td>
          <td class="p-body-colum">{{ record.email }}</td>
          <td class="p-body-colum">{{ record.mobilePhone }}</td>
          <td class="p-body-colum">{{ record.workPhone }}</td>
          <td class="p-body-colum">{{ record.fax }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No records found</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorleft" let-state>
        <label style="font-weight: 400">{{ createListWebAPI.paging.totalRecords }}
          {{
          createListWebAPI.paging.totalRecords > 1 ? "results" : "result"
          }}</label>
      </ng-template>
      <ng-template pTemplate="paginatorright" let-state>
        <div>
          <label style="margin-top: 1px; margin-right: 8px; font-weight: 400">Go to Page</label>
          <input type="text" pInputText pKeyFilter="int" [(ngModel)]="createListWebAPI.paging.currentPage" width="3"
            maxlength="3" style="
              text-align: center;
              width: 4rem;
              margin-right: 8px;
              border-radius: 20px;
            " (change)="onCreateListGotoPage()" />
        </div>
      </ng-template>
    </p-table>
  </div>

  <div class="pb-35"></div>
  <ng-template pTemplate="footer text-left">
    <div class="text-right fixedpos">
      <a (click)="cancelCreateNewList()" class="link-text" style="
          color: #2e2e2e;
          cursor: pointer;
          margin-right: 17.5px;
          font-weight: normal;
          line-height: 34px;
          text-decoration: underline;
        " styleClass="p-button-secondary">Cancel</a>
      <p-button (click)="saveCreateNewList()" [style]="{ 'margin-left': '17.5px' }" label="Save"></p-button>
    </div>
  </ng-template>
</p-dialog>