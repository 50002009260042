import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FilterTypes } from '../enums/filter-types';
import { ISearchModel, SearchModel } from '../models/search-model';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FiltersComponent implements OnInit {

  @Input() columns: Array<any> = new Array<any>();
  //@ts-ignore
  @Input() searchModel: SearchModel = new SearchModel({} as ISearchModel);
  searchKeyword: string = "";
  selectedFilterColumns: Array<any> = new Array<any>();
  FilterTypes = FilterTypes;
  @Output() updateTable: EventEmitter<any> = new EventEmitter<any>();
  @Input() hideSearchBar: boolean = false;
  selectedItem: any;
  suggestions: Array<string>;
  @ViewChild('dateSelector') dateSelector: OverlayPanel;
  @ViewChild('op') overlayPanel: OverlayPanel;
  constructor(private changeDetectionRefernce: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.suggestions = this.columns.map(x => x.header.toLowerCase()); 
  }

  suggestionSearch(event: any) {
    this.suggestions = this.columns.map(x => x.header.toLowerCase()).filter(header => header.includes(event.query));
  }

  onSuggestionSelected($event: any){
    const selectedColumn = this.columns.find(x => x.header.toLowerCase() === $event);
    this.selectFilter(selectedColumn);
    this.selectedItem = '';
  }

  selectFilter(column: any) {

    this.selectedFilterColumns.push(column);
    this.selectedFilterColumns = [...this.selectedFilterColumns];

    if(column.filterType === FilterTypes.SELECTABLE){
      column.selected = true;
      this.updateTable.emit();
    }

    this.overlayPanel.hide();
  }

  removeFilter(column: any) {
    column.filterValues.length = 0;
    this.selectedFilterColumns = this.selectedFilterColumns.filter(x => x.field != column.field);
    this.selectedFilterColumns = [...this.selectedFilterColumns];

    if(column.filterType === FilterTypes.DATE) {
      column.beginDate = null;
      column.endDate = null;
    }

    if(column.filterType === FilterTypes.SELECTABLE){
      column.selected = false;
    }

    this.updateTable.emit();
  }

  onFilterChange(closeOverlay: boolean = false) {
    this.selectedFilterColumns = [...this.selectedFilterColumns];
    if(closeOverlay){
      this.dateSelector.hide();
    }
    this.updateTable.emit();
  }

  search($event) {
    this.updateTable.emit();
  }

  public detectFilterChanges(){
    this.changeDetectionRefernce.detectChanges();
  }

}
