import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { DropdownModule } from "primeng/dropdown";
import { FileUpload } from 'primeng/fileupload';
import { OverlayPanel } from 'primeng/overlaypanel';
import { formState } from 'src/helpers/formState.helper';
import { RequestResponse } from 'src/models/RequestResponse';
import { UserManagementService } from 'src/services/userManagement.service';
import { AccountUser } from 'src/models/AccountUser';
import { AccountsService } from '../services/accounts.service';
import { ToastService } from 'src/services/toast.service';
import { AppSettings } from 'src/appSettings';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { USAStatesService } from 'src/services/usstates.service';
import { ConfirmModalComponent } from 'src/components/marketing/designlibrary/dialogs/confirm-modal/confirm-modal.component';
import { Guid } from "guid-typescript";
import { StorageHelper } from '../../helpers/StorageHelper';
import { environment } from 'src/environments/environment';
import { PermissionGroupHelper } from 'src/helpers/PermissionGroupHelper';
import { BillingService } from 'src/services/billingService';
import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { CropperDialog } from './cropper-dialog';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'userEditor-root',
  templateUrl: './userEditor.component.html',
  styleUrls: ['./userEditor.component.scss'],
})

export class userEditorComponent implements OnInit {
  userForm: FormGroup | any = null;
  @Output() onLogOutClick: EventEmitter<any> = new EventEmitter<any>(true);
  @Output() onTokenTimeout: EventEmitter<any> = new EventEmitter<any>(true);
  @Output() onChangePasswordClick: EventEmitter<any> = new EventEmitter<any>(
    true
  );

  @ViewChild('opStates', { static: false }) opStates: OverlayPanel;
  @ViewChild('imageUploader', { static: false }) imageUploader: FileUpload;

  /** AppSettings defined image size label for HTML content */
  readonly IMAGE_SIZE_LABEL = AppSettings.IMAGE_SIZE_LABEL;

  /** used for showing/hiding spinner, progress bar or skeleton. */
  loadingPage: boolean = false;

  tenantUserId: string = null;
  tenantId: any = null;
  companyUserId: any = null;
  firstName: any = null;
  lastName: any = null;
  emailId: any = null;
  phoneNumber: any = null;
  companyUserFax: any = null;
  companyUserWorkPhone: any = null;
  companyUserNmls: any = null;
  companyUserTenantId: any = null;
  companyUserTenantUserId: any = null;
  losUser: any = null;
  usStates: any[] = [];
  roles: SelectItem[] = [];
  selectedRole: string = "";
  currentClientID: string;

  defaultTeam: SelectItem[] = [
    { label: "Company", value: Guid.createEmpty() },
  ];

  selectedDefaultTeam: Guid = Guid.createEmpty(); //default value set to COMPANY

  webAPI: RequestResponse = new RequestResponse();
  user: AccountUser = new AccountUser();

  // Data Formatting and Validation
  validator: formState = new formState();
  showErrors: boolean = false;
  isShown: boolean = false;
  isCompanyUser: boolean = false;
  isCompanyNewUser: boolean = false;
  emailConfirmed: boolean = false;
  isResendConfirmationShown: boolean = false;

  // image uploading
  /** Reads the image file from disk. */
  fileReader: FileReader = new FileReader();

  /** Thumbnail image read from the database. */
  imageBytes: any = null;

  /** Uploaded image. */
  uploadedImageBytes: any = null;
  uploadedImagefile: any = null;

  /** Indicates that the database has an image saved. */
  imageAvailable: boolean = false;

  /** Indicates we are uploading a new image (when we save). */
  imageUploaded: boolean = false;

  /** Indicates we want to clear the database image (when we save). */
  imageCleared: boolean = false;
  imageSrc: string = null;
  storageHelper: StorageHelper;
  isValidUserEmail: boolean = true;
  role = ''

  //Premium related data 
  userPreminumDetails: any;
  isShowSubscription = false;
  companyName = '';
  billingOwner = '';

  isSimpleEmailEditor = true;

  emailSignatureHtml = '';
  userEmailId = '';
  isChangePasswordVisible = false;
  isTestMode = false;
  isMarketingPreference = false;
  checked2 = true;

  oldPassword: string = '';
  newPassword1: string = '';
  newPassword2: string = '';
  errors: string[] = [];
  newPasswordsMatch: boolean = false;
  oldPasswordIsCorrectLength: boolean = false;
  passwordIsCorrectLength: boolean = false;
  passwordContainsUpper: boolean = false;
  passwordContainsLower: boolean = false;
  passwordContainsNumber: boolean = false;
  passwordContainsSpecial: boolean = false;
  isPasswordValid = false;
  showResetPasswordDialog = false;
  resetPasswordMessage = ''

  currentShowPassword = false;
  newShowPassword = false;
  confirmShowPassword = false;

  /**
   * Constructor
   *
   * @param confirmationService ConfirmationService required for <p-confirmDialog>
   */
  constructor(
    private toastService: ToastService,
    private userManagementService: UserManagementService,
    private accountsService: AccountsService,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialogService: DialogService,
    private usStatesService: USAStatesService,
    private billingService: BillingService,
    private dialog: LyDialog,
    private cd: ChangeDetectorRef
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.tenantUserId = this.router.getCurrentNavigation().extras.state.tenantUserKey;
      this.losUser = this.router.getCurrentNavigation().extras.state.losUser;
      this.isShown = true;

      if (this.losUser == "Yes") {
        this.isCompanyUser = true;
        this.tenantId = this.router.getCurrentNavigation().extras.state.tenantId;
        this.companyUserId = this.router.getCurrentNavigation().extras.state.companyUserId;
        const myArray = this.router.getCurrentNavigation().extras.state.name.split(" ");
        this.firstName = myArray[1];
        this.lastName = myArray[0].replace(',', '');
        this.emailId = this.router.getCurrentNavigation().extras.state.emailId;
        this.phoneNumber = this.router.getCurrentNavigation().extras.state.phoneNumber;
        this.companyUserFax = this.router.getCurrentNavigation().extras.state.companyUserFax;
        this.companyUserWorkPhone = this.router.getCurrentNavigation().extras.state.companyUserWorkPhone;
        this.companyUserNmls = this.router.getCurrentNavigation().extras.state.companyUserNmls;
        this.companyUserTenantId = this.router.getCurrentNavigation().extras.state.companyUserTenantId;
        this.companyUserTenantUserId = this.router.getCurrentNavigation().extras.state.companyUserTenantUserId;
      }
    }
    this.imageSrc = '';
    this.usStates = usStatesService.getUSAStateCodesAndNames();
    this.storageHelper = new StorageHelper();
    this.currentClientID = environment.clientId;

    if (this.currentClientID === 'AduvoAdmin') {
      this.roles = [
        { label: "Focus IT System Admin", value: 0 },
        { label: "Focus IT User", value: 1 },
      ];
      //this.selectedRole = "0"
    }
    else {
      this.roles = [
        { label: "Company Admin", value: 2 },
        { label: "Marketing Admin", value: 4 },
        { label: "Standard User", value: 3 },
        { label: "Standard User With Marketing", value: 5 },
      ];
      //this.selectedRole = "2"
    }
  }

  //***************************************************************************************
  // Component Initialization
  //***************************************************************************************
  /**
   * Occurs upon component initialization
   */
  async ngOnInit(): Promise<void> {
    // obtain data for the page
    this.createForm();
    if (this.tenantUserId == "00000000-0000-0000-0000-000000000000") {
      this.isCompanyNewUser = true;
      this.loadCompanyUser();
    }
    else if (this.tenantUserId != null) {
      this.isCompanyNewUser = false;
      this.getPremiumRelatedData();
      this.getAdditionalDetails();
      this.loadUser(this.tenantUserId);
      this.getUserEmailConfirmed(this.tenantUserId);
    }
  }

  createForm() {
    return new Promise((resolve, reject) => {
      this.userForm = this.formBuilder.group({
        userEmailId: [null, [Validators.required]],
        userRole: [null, [Validators.required]],
        defaultTeam: [null],
        firstName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(64),],],
        lastName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(64),],],
        workPhone: [null, [Validators.pattern(/(^\d{10}$)|(^\d{3}[-]\d{3}[-]\d{4}$)|(^\d{3}[.]\d{3}[.]\d{4}$)|(^(\()\d{3}(\))[\s]\d{3}-\d{4}$)/s), Validators.maxLength(25)]],
        mobilePhone: [null, [Validators.pattern(/(^\d{10}$)|(^\d{3}[-]\d{3}[-]\d{4}$)|(^\d{3}[.]\d{3}[.]\d{4}$)|(^(\()\d{3}(\))[\s]\d{3}-\d{4}$)/s), Validators.maxLength(25)]],
        fax: [null, [Validators.pattern(/(^\d{10}$)|(^\d{3}[-]\d{3}[-]\d{4}$)|(^\d{3}[.]\d{3}[.]\d{4}$)|(^(\()\d{3}(\))[\s]\d{3}-\d{4}$)/s), Validators.maxLength(25)]],
        nmls: [null],
        twiterUrl: [null, Validators.pattern(/(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])?/),],
        facebookUrl: [null, Validators.pattern(/(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])?/),],
        linkedInUrl: [null, Validators.pattern(/(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])?/),],
        marketingPreference: [null],
        testMode: [null],
        emailSignatureHtml: [null],
        currentPassword: [null],
        newPassword: [null],
        confirmPassword: [null]
      });
      resolve(true);
    });
  }

  openCropperDialog(event) {
    if (event.target == null || event.target.files[0].length == 0) {
      return;
    }

    // even if someone set the mode to multiple, we only allow one image
    if (event.target.files[0].size > AppSettings.MAX_IMAGE_SIZE) {
      this.toastService.showError(AppSettings.IMAGE_SIZE_ERROR_MSG);
      return;
    }

    // indicate an image is uploaded
    this.imageUploaded = true;
    // read the image file
    this.doUpload(event.target.files[0]);

    this.dialog.open<CropperDialog, Event>(CropperDialog, {
      data: event,
      width: 320,
      disableClose: true
    }).afterClosed.subscribe((result?: ImgCropperEvent) => {
      if (result) {
        this.imageSrc = result.dataURL;
        this.cd.markForCheck();
      }
    });
  }

  validateUserEmail() {
    if (this.userForm.value.userEmailId == "") {
      this.isValidUserEmail = true;
    }
    else {
      this.isValidUserEmail = this.validateEmail(this.userForm.value.userEmailId);
    }
  }

  validateEmail(emailAddress: any) {
    let isValid = false;
    const singleEmailRegularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValid = singleEmailRegularExpression.test(String(emailAddress).toLowerCase());
    return isValid;
  }

  async getUserEmailConfirmed(tenantUserId: any) {
    try {
      var response = await this.userManagementService.GetUserEmailConfirmed(tenantUserId);
      if (response.status == 200) {
        this.emailConfirmed = response.data;
        if (this.emailConfirmed == false) {
          this.isResendConfirmationShown = true;
        }
      }
    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  async loadUser(tenantUserId: any) {
    await this.getUserData(tenantUserId).then((value) => {
      if (this.user) {
        this.imageSrc = this.user.Logo;
        this.userForm.setValue({
          userEmailId: this.user.Email,
          userRole: this.user.Role,
          defaultTeam: this.selectedDefaultTeam,
          firstName: this.user.FirstName === null ? '' : this.user.FirstName,
          lastName: this.user.LastName === null ? '' : this.user.LastName,
          workPhone: this.user.WorkPhone === null || this.user.WorkPhone === 'null' ? '' : this.formatNumber(this.user.WorkPhone),
          mobilePhone: this.user.MobilePhone === null || this.user.MobilePhone === 'null' ? '' : this.formatNumber(this.user.MobilePhone),
          fax: this.user.Fax === null || this.user.Fax === 'null' ? '' : this.formatNumber(this.user.Fax),
          nmls: this.user.NmlsId === null ? '' : this.user.NmlsId,
          twiterUrl: this.user.TwitterURL === null || this.user.TwitterURL === 'null' ? '' : this.user.TwitterURL,
          facebookUrl: this.user.FaceBookURL === null || this.user.FaceBookURL === 'null' ? '' : this.user.FaceBookURL,
          linkedInUrl: this.user.LinkedInURL === null || this.user.LinkedInURL === 'null' ? '' : this.user.LinkedInURL,
          marketingPreference: this.isMarketingPreference,
          testMode: this.isTestMode,
          emailSignatureHtml: this.user.Signature,
          currentPassword: this.oldPassword,
          newPassword: this.newPassword1,
          confirmPassword: this.newPassword2,
        });
      }
    });
  }

  workPhoneFormat(event: any) {//returns (###) ###-####
    let input: any = this.userForm.controls['workPhone'].value;
    input = this.formatNumber(input);    
    this.userForm.controls['workPhone'].setValue(input);
  }

  faxFormat(event: any) {//returns (###) ###-####
    let input: any = this.userForm.controls['fax'].value;
    input = this.formatNumber(input);    
    this.userForm.controls['fax'].setValue(input);
  }

  mobilePhoneFormat(event: any) {//returns (###) ###-####
    let input: any = this.userForm.controls['mobilePhone'].value;
    input = this.formatNumber(input);    
    this.userForm.controls['mobilePhone'].setValue(input);
  }

  formatNumber(input) {
    if(input == '' || input == null || input === undefined) {
    } else {
      input = input.replace(/\D/g,'').substring(0,10); //Strip everything but 1st 10 digits
      var size = input.length;
      if (size>0) {input="("+input}
      if (size>3) {input=input.slice(0,4)+") "+input.slice(4)}
      if (size>6) {input=input.slice(0,9)+"-" +input.slice(9)}
    }    
    return input;
  }

  async getUserData(tenantUserId: any) {
    // start the progress bar/spinner or display a skeleton.
    this.loadingPage = true;
    try {
      this.webAPI.data = [];
      var response = await this.userManagementService.GetUser(tenantUserId);
      this.webAPI.data = response.data;
      this.user = JSON.parse(JSON.stringify(this.accountsService.toInstance(this.webAPI.data)));
      this.role = new PermissionGroupHelper().DisplayString(this.user.Role);
      this.loadingPage = false;
    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  async loadCompanyUser() {
    this.userForm.setValue({
      userEmailId: this.emailId,
      userRole: null,
      defaultTeam: null,
      firstName: this.firstName,
      lastName: this.lastName,
      workPhone: this.companyUserWorkPhone,
      mobilePhone: this.phoneNumber,
      fax: this.companyUserFax,
      nmls: this.companyUserNmls,
      twiterUrl: '',
      facebookUrl: '',
      linkedInUrl: '',
      marketingPreference: this.isMarketingPreference,
      testMode: this.isTestMode,
      emailSignatureHtml: this.user.Signature,
      currentPassword: this.oldPassword,
      newPassword: this.newPassword1,
      confirmPassword: this.newPassword2,
    });
  }

  /**
   * Called when the 'Save' button is clicked.
   *
   * @param event
   */
  async onSaveButtonClick() {
    if (this.userForm.valid && this.isValidUserEmail) {
      var ref = this.dialogService.open(ConfirmModalComponent, {
        width: '650px',
        contentStyle: { 'max-height': '500px', overflow: 'auto' },
        styleClass: 'confirm-modal',
        baseZIndex: 10000,
        data: {
          message: "Are you sure you want to save '" + this.userForm.value.firstName + " " + this.userForm.value.lastName + "' profile?",
          successButtonText: 'Yes, I want to save',
          cancelButtonText: 'Cancel',
        },
      });

      ref.onClose.subscribe(async (result: boolean) => {
        if (result) {
          //Actual logic to perform upon confirmation
          this.saveProfile();
        }
      });
    } else {
      this.toastService.showError('Please validate all data..');
    }
  }

  /**
   * Called when the profile is saved successfully.
   * (Displays success toast and closes the error dialog)
   */
  async saveProfile() {
    this.loadingPage = true;
    try {
      if(this.billingOwner == 'Company' || this.billingOwner == 'Individual') {
        if(this.userForm.value.nmls == null || this.userForm.value.nmls == '' || this.userForm.value.nmls === undefined) {
          this.toastService.showError('User profile does not include all of the information required to meet compliance standards. Please ensure that their NMLS number have been set under user profile.');
          this.loadingPage = false;
          return ;
        }
      }

      let webAPI: RequestResponse = new RequestResponse();
      const formData: FormData = new FormData();
      formData.append('emailId', this.userForm.value.userEmailId);
      formData.append('userRole', this.userForm.value.userRole);
      formData.append('fileImage', this.uploadedImagefile);
      formData.append('firstName', this.userForm.value.firstName);
      formData.append('lastName', this.userForm.value.lastName);
      formData.append('logo', this.imageSrc);
      formData.append('nmls', this.userForm.value.nmls == null ? "" : this.userForm.value.nmls);
      formData.append('workPhone', this.userForm.value.workPhone == null ? "" : this.userForm.value.workPhone);
      formData.append('phoneNumber', this.userForm.value.mobilePhone == null ? "" : this.userForm.value.mobilePhone);
      formData.append('fax', this.userForm.value.fax == null ? "" : this.userForm.value.fax);
      formData.append('twitterUrl', this.userForm.value.twiterUrl == null ? "" : this.userForm.value.twiterUrl);
      formData.append('faceBookUrl', this.userForm.value.facebookUrl == null ? "" : this.userForm.value.facebookUrl);
      formData.append('linkedInUrl', this.userForm.value.linkedInUrl == null ? "" : this.userForm.value.linkedInUrl);
      formData.append('signature', this.emailSignatureHtml);

      var response;
      if (this.isShown && !this.isCompanyNewUser) {
        response = await this.userManagementService.EditUser(this.tenantUserId, formData);
      }
      else {
        if (this.isCompanyNewUser) {
          response = await this.userManagementService.AddToPulse(this.companyUserId, this.companyUserTenantId, formData);
        }
        else {
          response = await this.userManagementService.CreateUser(formData);
        }
      }
      this.loadingPage = false;
      if (response.status == 0) {
        this.toastService.showError(response.message);
      }
      else if (response.status == 200) {
        // set the new tenant user id from response.
        this.tenantUserId = response.message;
        this.saveAdditionalDetails();

        if (this.isShown && !this.isCompanyNewUser) {
          this.toastService.showSuccess("The user was saved.");
        }
        else {
          let mes = "User was created and emailed the instructions to " + this.userForm.value.userEmailId;
          this.toastService.showSuccess(mes);
        }
        //clear cache
        this.storageHelper.ClearUserList(this.tenantId);
        this.storageHelper.ClearUserList('');
        this.goToPage("account/manage-users");
      }
    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  async onDeleteUserButtonClick() {
    if (this.isCompanyUser && this.losUser == "Yes") {
      let mes = "Please confirm you want to remove '" + this.userForm.value.firstName + " " + this.userForm.value.lastName + "' from Aduvo?";
      var ref = this.dialogService.open(ConfirmModalComponent, {
        width: '650px',
        contentStyle: { 'max-height': '500px', overflow: 'auto' },
        styleClass: 'confirm-modal',
        baseZIndex: 10000,
        data: {
          message: mes,
          successButtonText: 'Yes, I want to remove',
          cancelButtonText: 'Cancel',
        },
      });

      ref.onClose.subscribe(async (result: boolean) => {
        if (result) {
          await this.RemoveFromPulse();
        }
      });
    }
    else {
      let mes = "Do you want to delete '" + this.userForm.value.firstName + " " + this.userForm.value.lastName + "'?";
      var ref = this.dialogService.open(ConfirmModalComponent, {
        width: '650px',
        contentStyle: { 'max-height': '500px', overflow: 'auto' },
        styleClass: 'confirm-modal',
        baseZIndex: 10000,
        data: {
          message: mes,
          successButtonText: 'Yes, I want to delete',
          cancelButtonText: 'Cancel',
        },
      });

      ref.onClose.subscribe(async (result: boolean) => {
        if (result) {
          await this.DeleteUser();
        }
      });
    }
  }

  async DeleteUser() {
    this.loadingPage = true;
    try {
      var response = await this.userManagementService.DeleteUser(this.tenantUserId);
      this.loadingPage = false;
      if (response.status == 200) {
        let successMessage = this.userForm.value.firstName + " " + this.userForm.value.lastName + "was delete.";
        this.toastService.showSuccess(successMessage);
        this.storageHelper.ClearUserList(this.tenantId);
        this.storageHelper.ClearUserList('');
        this.goToPage("account/manage-users");
      }
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  async RemoveFromPulse() {
    this.loadingPage = true;
    try {
      let response = await this.userManagementService.RemoveFromPulse(this.companyUserId, this.tenantUserId);
      this.loadingPage = false;
      //clear cachce 
      this.storageHelper.ClearUserList(this.tenantId);
      this.storageHelper.ClearUserList('');
      this.goToPage("account/manage-users");
      this.toastService.showSuccess('User removed from Aduvo');
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  async onResetPasswordButtonClick() {
    let mes = "Do you want to reset the password of '" + this.userForm.value.firstName + " " + this.userForm.value.lastName + "'?";
    var ref = this.dialogService.open(ConfirmModalComponent, {
      width: '650px',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      styleClass: 'confirm-modal',
      baseZIndex: 10000,
      data: {
        message: mes,
        successButtonText: 'Yes, I want to reset password',
        cancelButtonText: 'Cancel',
      },
    });

    ref.onClose.subscribe(async (result: boolean) => {
      if (result) {
        await this.ResetPassword();
      }
    });
  }

  async ResetPassword() {
    this.loadingPage = true;
    try {
      var response = await this.userManagementService.ResetPassword(this.tenantUserId);
      this.loadingPage = false;
      if (response.status == 200) {
        let successMessage = "Instructions to reset the password were sent to " + this.user.Email + ".";
        this.toastService.showSuccess(successMessage);
        this.goToPage("account/manage-users");
      }
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  async onResendConfirmationButtonClick() {
    this.loadingPage = true;
    try {
      var response = await this.userManagementService.SendConfirmation(this.tenantUserId);
      this.loadingPage = false;
      if (response.status == 200) {
        let successMessage = "Email confirmation was sent to " + this.user.Email + ".";
        this.toastService.showSuccess(successMessage);
        this.goToPage("account/manage-users");
      }
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  goToPage(pageName: string) {
    this.router.navigate([`${pageName}`]);
  }

  //***************************************************************************************
  // Image Uploading
  //***************************************************************************************
  /**
   * Called when an image is to be uploaded.
   * (we will only allow a single image)
   *
   * @param event
   */
  async imageUploadHandler(event: any) {
    // are any files selected
    if (event.files == null || event.files.length == 0) {
      return;
    }

    // even if someone set the mode to multiple, we only allow one image
    if (event.files[0].size > AppSettings.MAX_IMAGE_SIZE) {
      this.imageUploader.clear();
      this.toastService.showError(AppSettings.IMAGE_SIZE_ERROR_MSG);
      return;
    }

    // indicate an image is uploaded
    this.imageUploaded = true;
    // read the image file
    this.doUpload(event.files[0]);
  }

  /**
   * Reads an image from disk.
   *
   * @param file
   */
  async doUpload(file: any) {
    this.uploadedImagefile = file;
    this.fileReader.onload = (e) => (this.uploadedImageBytes = e.target.result);
    // read the image
    this.fileReader.readAsBinaryString(file);
  }

  /**
   * Undo (clear) the uploded image.
   *
   * @param event
   */
  onUndoUpload(event: any) {
    this.imageUploader.clear();
    this.uploadedImageBytes = null;
    this.imageUploaded = false;
    this.uploadedImagefile = null;
  }

  /**
   * Clear the image.
   * (when the record is saved the datase image will be cleared)
   *
   * @param event
   */
  onClearImage(event: any) {
    this.imageAvailable = false;
    this.imageCleared = true;
  }

  /**
   * Undo the Clear image.
   *
   * @param event
   */
  onUndoClearImage(event: any) {
    this.imageAvailable = true;
    this.imageCleared = false;
  }

  /**
   * Called after save to set image (control) settings.
   */
  // async setPostSaveImageSettings() {
  //   this.imageUploader.clear();
  //   this.imageUploaded = false;
  //   this.uploadedImageBytes = null;
  //   this.uploadedImagefile = null;
  //   this.imageCleared = false;
  // }

  getPremiumRelatedData() {
    if (this.tenantUserId) {
      this.billingService.Getusersubscriptiondetail(this.tenantUserId).subscribe(
        (res) => {
          if (res.data) {
            if (!res.data.isLimited) {
              this.userPreminumDetails = res.data;
              this.companyName = res.data.companyName;
              this.billingOwner = res.data.billingOwner;
              this.isShowSubscription = true;
            } else {
              this.isShowSubscription = false;
            }
          }
        },
        (err) => {
          this.isShowSubscription = false;
        }
      );
    }
  }

  showChangePasswordArea() {
    this.isChangePasswordVisible = !this.isChangePasswordVisible;
    this.validateInputs();
  }

  validateInputs(): boolean {
    this.newPasswordsMatch = this.newPassword1 === this.newPassword2 && this.newPassword1.length >= 1;
    this.passwordContainsLower = this.newPassword1.match('a|b|c|d|e|f|g|h|i|j|k|l|m|n|o|p|q|r|s|t|u|v|w|x|y|z') != null;
    this.passwordContainsUpper = this.newPassword1.match('A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z') != null;
    this.passwordContainsNumber = this.newPassword1.match('0|1|2|3|4|5|6|7|8|9') != null;
    this.passwordContainsSpecial = this.newPassword1.match('\\!|\\@|\\#|\\$|\\%|\\^|\\&|\\*|\\+|\\-|\\=|\\_') != null;
    this.passwordIsCorrectLength = this.newPassword1.length >= 6 && this.newPassword1.length <= 64;
    this.oldPasswordIsCorrectLength = this.oldPassword.length >= 8 && this.oldPassword.length <= 64;
    this.isPasswordValid = this.newPasswordsMatch && this.passwordContainsLower && this.passwordContainsUpper && this.passwordContainsNumber && this.passwordContainsSpecial && this.passwordIsCorrectLength && this.oldPasswordIsCorrectLength;
    return this.isPasswordValid;
  }

  onSavePasswordClick() {
    if (this.validateInputs()) {
      let data = { tenantUserId: this.tenantUserId, currentPassword: this.oldPassword, newPassword: this.newPassword1 };

      try {
        this.billingService.UpdatePassword(data).subscribe((res) => {
          if (res.status == 200) {
            this.toastService.showSuccess('Password changed successfully');
          } else {
            this.toastService.showError('Your password cannot be changed, this is due to error: ' + res.message);
          }
        },
          (err) => {
            this.toastService.showError('Your password cannot be changed please contact your administrator for assistance');
            this.isShowSubscription = false;
          });

        return true;
      }
      catch (error) {
        this.errors.push(error);
        return false;
      }
    }
    else {
      return false;
    }
  }

  onInput() {
    this.validateInputs();
  }

  hideChangePasswordArea() {
    this.isChangePasswordVisible = false;
    this.oldPassword = '';
    this.newPassword1 = '';
    this.newPassword2 = '';
  }

  onResetPassword(event: any) {
    this.resetPasswordMessage =
      "Do you want to reset the password?";
    this.showResetPasswordDialog = true;
  }

  async resetPasswordConfirmation() {
    let userIds = [];
    let response;
    if (this.loadingPage) return;
    this.loadingPage = true;
    try {
      userIds.push({ 'tenantUserId': this.tenantUserId });
      let data = userIds;
      response = await this.billingService.resetPasswords(data);
      if (response.status == 200) {
        let successMessage = '';
        successMessage = 'Instructions to reset the password were sent to ' + this.userEmailId + '.';
        this.toastService.showSuccess(successMessage);
      } else {
        this.toastService.showError('Reset password email sent fail');
      }
      this.showResetPasswordDialog = false;
      this.loadingPage = false;
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  saveAdditionalDetails() {
    let data = {
      tenantUserId: this.tenantUserId,
      emailSignatureHTML: this.emailSignatureHtml,
      marketingMode: this.isTestMode,
      marketingPreference: this.isMarketingPreference
    }
    try {
      this.billingService.saveAdditionalDetails(data).subscribe((res) => {
        if (res.status == 200) {
          console.log('Additional information changed successfully');
        } else {
          this.toastService.showError('Additional information cannot be saved, this is due to error: ' + res.message);
        }
      },
        (err) => {
          this.toastService.showError('Additional information cannot be saved, please contact your administrator for assistance');
          this.isShowSubscription = false;
        });

      return true;
    }
    catch (error) {
      return false;
    }
  }

  getAdditionalDetails() {
    if (this.tenantUserId) {
      this.billingService.GetAdditionalDetails(this.tenantUserId).subscribe(
        (res) => {
          if (res.data) {
            this.isTestMode = res.data.marketingMode;
            this.isMarketingPreference = res.data.marketingPreference;
          }
        },
        (err) => {
        }
      );
    }
  }
}
