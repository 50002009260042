import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators, } from '@angular/forms';
import { Button } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from "primeng/dropdown";
import { FileUpload } from 'primeng/fileupload';
import { OverlayPanel } from 'primeng/overlaypanel';
import { formState } from 'src/helpers/formState.helper';
import { PermissionGroupHelper } from 'src/helpers/PermissionGroupHelper';
import { NGENMasterContact } from 'src/models/NGENMasterContact';
import { NGENTeam } from 'src/models/NGENTeam';
import { RequestResponse } from 'src/models/RequestResponse';
import { AccountsService } from '../services/accounts.service';
import { ImageService } from 'src/services/image.service';
import { ToastService } from 'src/services/toast.service';
import { USAStatesService } from 'src/services/usstates.service';
import { changePasswordComponent } from 'src/app/usermanagement/changePassword.component';
import { AppSettings } from 'src/appSettings';
import { UserTokenService } from 'src/services/user-token.service';
import { UserToken } from 'src/models/UserToken';
import { AccountUser } from 'src/models/AccountUser';
import { ProfileObserverService } from 'src/services/profileObserver.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmModalComponent } from 'src/components/marketing/designlibrary/dialogs/confirm-modal/confirm-modal.component';
import { Guid } from "guid-typescript";
import { BillingService } from 'src/services/billingService';
import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { CropperDialog } from './cropper-dialog';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'userProfile-root',
  templateUrl: './userProfile.component.html',
  styleUrls: ['./userProfile.component.scss'],
})
export class userProfileComponent implements OnInit, AfterViewInit {
  companyForm: FormGroup | any = null;
  @Output() onLogOutClick: EventEmitter<any> = new EventEmitter<any>(true);
  @Output() onTokenTimeout: EventEmitter<any> = new EventEmitter<any>(true);
  @Output() onChangePasswordClick: EventEmitter<any> = new EventEmitter<any>(true);

  // @ViewChild('myControlId', { static: boolean }) propertyName: type
  @ViewChild('btnAddLicense', { static: false }) btnAddLicense: Button;
  @ViewChild('opStates', { static: false }) opStates: OverlayPanel;
  @ViewChild('imageUploader', { static: false }) imageUploader: FileUpload;
  @ViewChild('changePasswordDialog', { static: false })
  changePasswordDialog: changePasswordComponent;

  /** AppSettings defined image size label for HTML content */
  readonly IMAGE_SIZE_LABEL = AppSettings.IMAGE_SIZE_LABEL;

  /** used for showing/hiding spinner, progress bar or skeleton. */
  loadingPage: boolean = false;

  usStates: any[] = [];

  webAPI: RequestResponse = new RequestResponse();
  user: AccountUser = new AccountUser();
  role: string = '';

  // Data Formatting and Validation
  validator: formState = new formState();
  showErrors: boolean = false;
  // blocks calls to validate controls until we are sure controls are initialized.
  canValidate: boolean = false;

  // image uploading
  /** Reads the image file from disk. */
  fileReader: FileReader = new FileReader();
  /** Thumbnail image read from the database. */
  imageBytes: any = null;
  /** Uploaded image. */
  uploadedImageBytes: any = null;
  uploadedImagefile: any = null;
  /** Indicates that the database has an image saved. */
  imageAvailable: boolean = false;
  /** Indicates we are uploading a new image (when we save). */
  imageUploaded: boolean = false;
  /** Indicates we want to clear the database image (when we save). */
  imageCleared: boolean = false;
  imageSrc: string = null;
  /** Indicates we want to show the change password dialog. */
  showChangePassword: boolean = false;
  isAccountVerified: boolean = false;

  defaultTeam: SelectItem[] = [
    { label: "Company", value: Guid.createEmpty() },
  ];

  selectedDefaultTeam: Guid = Guid.createEmpty(); //default value set to COMPANY

  tenantUserId = '';

  //Premium related data 
  userPreminumDetails: any;
  isShowSubscription = false;
  companyName = '';
  billingOwner = '';

  emailSignatureHtml = '';
  userEmailId = '';
  isChangePasswordVisible = false;

  oldPassword: string = '';
  newPassword1: string = '';
  newPassword2: string = '';
  errors: string[] = [];
  newPasswordsMatch: boolean = false;
  oldPasswordIsCorrectLength: boolean = false;
  passwordIsCorrectLength: boolean = false;
  passwordContainsUpper: boolean = false;
  passwordContainsLower: boolean = false;
  passwordContainsNumber: boolean = false;
  passwordContainsSpecial: boolean = false;
  isPasswordValid = false;

  showResetPasswordDialog = false;
  resetPasswordMessage = ''
  isTestMode = false;
  isMarketingPreference = false;
  currentShowPassword = false;
  newShowPassword = false;
  confirmShowPassword = false;

  /**
   * Constructor
   *
   * @param http HTTPClient required to call actions in any .ts file in the services folder.
   * @param confirmationService ConfirmationService required for <p-confirmDialog>
   * @param messageService MessageService required for <p-toast>
   */
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private toastService: ToastService,
    private usStatesService: USAStatesService,
    private accountsService: AccountsService,
    private imageService: ImageService,
    private userTokenService: UserTokenService,
    private formBuilder: FormBuilder,
    private profileObserverService: ProfileObserverService,
    public dialogService: DialogService,
    private billingService: BillingService,
    private dialog: LyDialog,
    private cd: ChangeDetectorRef
  ) {
    this.usStates = usStatesService.getUSAStateCodesAndNames();
    this.imageSrc = null;
    this.tenantUserId = this.userTokenService.getUser().TenantUserId;
    this.userEmailId = this.userTokenService.getUser().email;
    this.getPremiumRelatedData();
    this.getAdditionalDetails();
  }

  //***************************************************************************************
  // Component Initialization
  //***************************************************************************************
  /**
   * Occurs upon component initialization
   */
  async ngOnInit(): Promise<void> {
    // obtain data for the page
    this.createForm();
    await this.loadCompany();
    this.isAccountVerified = this.userTokenService.getUser().email_verified;
  }

  createForm() {
    return new Promise((resolve, reject) => {
      this.companyForm = this.formBuilder.group({
        firstName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(64),],],
        lastName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(64),],],
        workPhone: [null, [Validators.pattern(/(^\d{10}$)|(^\d{3}[-]\d{3}[-]\d{4}$)|(^\d{3}[.]\d{3}[.]\d{4}$)|(^(\()\d{3}(\))[\s]\d{3}-\d{4}$)/s), Validators.maxLength(25)]],
        mobilePhone: [null, [Validators.pattern(/(^\d{10}$)|(^\d{3}[-]\d{3}[-]\d{4}$)|(^\d{3}[.]\d{3}[.]\d{4}$)|(^(\()\d{3}(\))[\s]\d{3}-\d{4}$)/s), Validators.maxLength(25)]],
        fax: [null, [Validators.pattern(/(^\d{10}$)|(^\d{3}[-]\d{3}[-]\d{4}$)|(^\d{3}[.]\d{3}[.]\d{4}$)|(^(\()\d{3}(\))[\s]\d{3}-\d{4}$)/s), Validators.maxLength(25)]],
        nmls: [null],
        twiterUrl: [null, Validators.pattern(/(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])?/),],
        facebookUrl: [null, Validators.pattern(/(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])?/),],
        linkedInUrl: [null, Validators.pattern(/(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])?/),],
        defaultTeam: [null],
        userEmailId: [null],
        currentPassword: [null],
        newPassword: [null],
        confirmPassword: [null],
        marketingPreference: [null],
        testMode: [null],
        emailSignatureHtml: [null]


      });
      resolve(true);
    });
  }

  async loadCompany() {
    await this.getData();
    if (this.user) {
      this.imageSrc = this.user.Logo;
      this.companyForm.setValue({
        firstName: this.user.FirstName === null ? '' : this.user.FirstName,
        lastName: this.user.LastName === null ? '' : this.user.LastName,
        workPhone: this.user.WorkPhone === null || this.user.WorkPhone === 'null' ? '' : this.formatNumber(this.user.WorkPhone),
        mobilePhone: this.user.MobilePhone === null || this.user.MobilePhone === 'null' ? '' : this.formatNumber(this.user.MobilePhone),
        fax: this.user.Fax === null || this.user.Fax === 'null' ? '' : this.formatNumber(this.user.Fax),
        nmls: this.user.NmlsId === null ? '' : this.user.NmlsId,
        twiterUrl: this.user.TwitterURL === null || this.user.TwitterURL === 'null' ? '' : this.user.TwitterURL,
        facebookUrl: this.user.FaceBookURL === null || this.user.FaceBookURL === 'null' ? '' : this.user.FaceBookURL,
        linkedInUrl: this.user.LinkedInURL === null || this.user.LinkedInURL === 'null' ? '' : this.user.LinkedInURL,
        defaultTeam: this.selectedDefaultTeam,
        userEmailId: this.userEmailId,
        currentPassword: this.oldPassword,
        newPassword: this.newPassword1,
        confirmPassword: this.newPassword2,
        marketingPreference: this.isMarketingPreference,
        testMode: this.isTestMode,
        emailSignatureHtml: this.emailSignatureHtml
      });
    }
  }

  /**
   * Occurs after view initialization
   */
  ngAfterViewInit(): void {
  }

  openCropperDialog(event) {
    if (event.target == null || event.target.files[0].length == 0) {
      return;
    }

    // even if someone set the mode to multiple, we only allow one image
    if (event.target.files[0].size > AppSettings.MAX_IMAGE_SIZE) {
      this.toastService.showError(AppSettings.IMAGE_SIZE_ERROR_MSG);
      return;
    }

    this.companyForm.markAsDirty();
    
    // indicate an image is uploaded
    this.imageUploaded = true;
    // read the image file
    this.doUpload(event.target.files[0]);

    this.dialog.open<CropperDialog, Event>(CropperDialog, {
      data: event,
      width: 320,
      disableClose: true
    }).afterClosed.subscribe((result?: ImgCropperEvent) => {
      if (result) {
        this.imageSrc = result.dataURL;
        this.cd.markForCheck();
      }
    });
  }

  workPhoneFormat(event: any) {//returns (###) ###-####
    let input: any = this.companyForm.controls['workPhone'].value;
    input = this.formatNumber(input);    
    this.companyForm.controls['workPhone'].setValue(input);
  }

  faxFormat(event: any) {//returns (###) ###-####
    let input: any = this.companyForm.controls['fax'].value;
    input = this.formatNumber(input);    
    this.companyForm.controls['fax'].setValue(input);
  }

  mobilePhoneFormat(event: any) {//returns (###) ###-####
    let input: any = this.companyForm.controls['mobilePhone'].value;
    input = this.formatNumber(input);    
    this.companyForm.controls['mobilePhone'].setValue(input);
  }

  formatNumber(input) {
    if(input == '' || input == null || input === undefined) {
    } else {
      input = input.replace(/\D/g,'').substring(0,10); //Strip everything but 1st 10 digits
      var size = input.length;
      if (size>0) {input="("+input}
      if (size>3) {input=input.slice(0,4)+") "+input.slice(4)}
      if (size>6) {input=input.slice(0,9)+"-" +input.slice(9)}
    }    
    return input;
  }

  //***************************************************************************************
  // Data Acquisition
  //***************************************************************************************
  /**
   * Retrieves all necessary data for this component.
   *
   * @param: currentPage - paging support.
   */
  async getData() {
    // start the progress bar/spinner or display a skeleton.
    this.loadingPage = true;

    try {
      this.webAPI.data = [];
      var response = await this.accountsService.GetLoggedOnUser();
      this.webAPI.data = response.data;
      this.user = JSON.parse(JSON.stringify(this.accountsService.toInstance(this.webAPI.data)));
      this.role = new PermissionGroupHelper().DisplayString(this.user.Role);
      this.loadingPage = false;
    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  /**
   * Called when the 'Save' button is clicked.
   *
   * @param event
   */
  async onSaveButtonClick() {
    if (this.companyForm.valid) {
      //var defTeam = this.companyForm.value.defaultTeam;

      var ref = this.dialogService.open(ConfirmModalComponent, {
        width: '650px',
        contentStyle: { 'max-height': '500px', overflow: 'auto' },
        styleClass: 'confirm-modal',
        baseZIndex: 10000,
        data: {
          message: 'Are you sure you want to save your profile?',
          successButtonText: 'Yes, I want to save',
          cancelButtonText: 'Cancel',
        },
      });

      ref.onClose.subscribe(async (result: boolean) => {
        if (result) {
          //Actual logic to perform upon confirmation
          this.saveProfile();
          this.saveAdditionalDetails();
        }
      });
    } else {
      this.toastService.showError('Please validate all data..');
    }
  }

  /**
   * Called when the profile is saved successfully.
   * (Displays success toast and closes the error dialog)
   */
  async saveProfile() {
    this.loadingPage = true;
    try {
      if(this.billingOwner == 'Company' || this.billingOwner == 'Individual') {
        if(this.companyForm.value.nmls == null || this.companyForm.value.nmls == '' || this.companyForm.value.nmls === undefined) {
          this.toastService.showError('User profile does not include all of the information required to meet compliance standards. Please ensure that their NMLS number have been set under my profile.');
          this.loadingPage = false;
          return ;
        }
      } 
      let webAPI: RequestResponse = new RequestResponse();
      const formData: FormData = new FormData();
      formData.append('fileImage', this.uploadedImagefile);
      formData.append('firstName', this.companyForm.value.firstName);
      formData.append('lastName', this.companyForm.value.lastName);
      formData.append('logo', this.imageSrc);
      formData.append('nmls', this.companyForm.value.nmls);
      formData.append('workPhone', this.companyForm.value.workPhone);
      formData.append('phoneNumber', this.companyForm.value.mobilePhone);
      formData.append('fax', this.companyForm.value.fax);
      formData.append('twitterUrl', this.companyForm.value.twiterUrl);
      formData.append('faceBookUrl', this.companyForm.value.facebookUrl);
      formData.append('linkedInUrl', this.companyForm.value.linkedInUrl);
      formData.append('signature', this.emailSignatureHtml);

      this.accountsService.EditProfile(formData).subscribe(response => {
        this.loadingPage = false;
        if (response.status == 200) {
          this.setPostSaveImageSettings();
          this.profileObserverService.setProfileImage(response.data.picture);
          this.imageSrc = response.data.picture;
          this.user.FirstName = response.data.firstName;
          this.user.LastName = response.data.lastName;
          var userToken = this.userTokenService.getUser();
          userToken.given_name = this.companyForm.value.firstName;
          userToken.family_name = this.companyForm.value.lastName;
          userToken.phone_number = this.companyForm.value.workPhone ?? this.companyForm.value.mobilePhone;
          userToken.given_name = this.companyForm.value.firstName;

          this.toastService.showSuccess('Profile saved successfully');
        } else
          this.toastService.showError(response.message);
      })

    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  //***************************************************************************************
  // Image Uploading
  //***************************************************************************************
  /**
   * Called when an image is to be uploaded.
   * (we will only allow a single image)
   *
   * @param event
   */
  async imageUploadHandler(event: any) {
    // are any files selected
    if (event.files == null || event.files.length == 0) {
      return;
    }

    // even if someone set the mode to multiple, we only allow one image
    if (event.files[0].size > AppSettings.MAX_IMAGE_SIZE) {
      this.imageUploader.clear();
      this.toastService.showError(AppSettings.IMAGE_SIZE_ERROR_MSG);
      return;
    }

    this.companyForm.markAsDirty();

    // indicate an image is uploaded
    this.imageUploaded = true;
    // read the image file
    this.doUpload(event.files[0]);
  }

  /**
   * Reads an image from disk.
   *
   * @param file
   */
  async doUpload(file: any) {
    this.uploadedImagefile = file;
    this.fileReader.onload = (e) => (this.uploadedImageBytes = e.target.result);
    // read the image
    this.fileReader.readAsBinaryString(file);
  }

  /**
   * Undo (clear) the uploded image.
   *
   * @param event
   */
  onUndoUpload(event: any) {
    this.imageUploader.clear();
    this.uploadedImageBytes = null;
    this.imageUploaded = false;
    this.uploadedImagefile = null;
  }

  /**
   * Clear the image.
   * (when the record is saved the datase image will be cleared)
   *
   * @param event
   */
  onClearImage(event: any) {
    this.imageAvailable = false;
    this.imageCleared = true;
  }

  /**
   * Undo the Clear image.
   *
   * @param event
   */
  onUndoClearImage(event: any) {
    this.imageAvailable = true;
    this.imageCleared = false;
  }

  /**
   * Called after save to set image (control) settings.
   */
  async setPostSaveImageSettings() {
    //this.imageUploader.clear();
    this.imageUploaded = false;
    this.uploadedImageBytes = null;
    this.uploadedImagefile = null;
    this.imageCleared = false;
  }

  isCompanyAdmin() {
    return new PermissionGroupHelper().IsCompanyAdmin(this.user.Role);
  }

  getPremiumRelatedData() {
    if (this.tenantUserId) {
      this.billingService.Getusersubscriptiondetail(this.tenantUserId).subscribe(
        (res) => {
          if (res.data) {
            if (!res.data.isLimited) {
              this.userPreminumDetails = res.data;
              this.companyName = res.data.companyName;
              this.billingOwner = res.data.billingOwner;
              this.isShowSubscription = true;
            } else {
              this.isShowSubscription = false;
            }
          }
        },
        (err) => {
          this.isShowSubscription = false;
        }
      );
    }
  }

  showChangePasswordArea() {
    this.isChangePasswordVisible = !this.isChangePasswordVisible;
    this.validateInputs();
  }

  validateInputs(): boolean {
    this.newPasswordsMatch = (this.newPassword1 === this.newPassword2) && this.newPassword1.length >= 1;
    this.passwordContainsLower = this.newPassword1.match('a|b|c|d|e|f|g|h|i|j|k|l|m|n|o|p|q|r|s|t|u|v|w|x|y|z') != null;
    this.passwordContainsUpper = this.newPassword1.match('A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z') != null;
    this.passwordContainsNumber = this.newPassword1.match('0|1|2|3|4|5|6|7|8|9') != null;
    this.passwordContainsSpecial = this.newPassword1.match('\\!|\\@|\\#|\\$|\\%|\\^|\\&|\\*|\\+|\\-|\\=|\\_') != null;
    this.passwordIsCorrectLength = this.newPassword1.length >= 8 && this.newPassword1.length <= 64;
    this.oldPasswordIsCorrectLength = this.oldPassword.length >= 8 && this.oldPassword.length <= 64;
    this.isPasswordValid = this.newPasswordsMatch && this.passwordContainsLower && this.passwordContainsUpper && this.passwordContainsNumber && this.passwordContainsSpecial && this.passwordIsCorrectLength && this.oldPasswordIsCorrectLength;
    return this.isPasswordValid;
  }

  onSavePasswordClick() {
    if (this.validateInputs()) {
      let data = { tenantUserId: this.tenantUserId, currentPassword: this.oldPassword, newPassword: this.newPassword1 };

      try {
        this.billingService.UpdatePassword(data).subscribe((res) => {
          if (res.status == 200) {
            this.toastService.showSuccess('Password changed successfully');
          } else {
            this.toastService.showError('Your password cannot be changed, this is due to error: ' + res.message);
          }
        },
          (err) => {
            this.toastService.showError('Your password cannot be changed please contact your administrator for assistance');
            this.isShowSubscription = false;
          });

        return true;
      }
      catch (error) {
        this.errors.push(error);
        return false;
      }
    }
    else {
      return false;
    }
  }

  onInput() {
    this.validateInputs();
  }

  hideChangePasswordArea() {
    this.isChangePasswordVisible = false;
    this.oldPassword = '';
    this.newPassword1 = '';
    this.newPassword2 = '';
  }

  onResetPassword(event: any) {
    this.resetPasswordMessage =
      "Do you want to reset the password?";
    this.showResetPasswordDialog = true;
  }

  async resetPasswordConfirmation() {
    let userIds = [];
    let response;
    if (this.loadingPage) return;
    this.loadingPage = true;
    try {
      userIds.push({ 'tenantUserId': this.tenantUserId });
      let data = userIds;
      response = await this.billingService.resetPasswords(data);
      if (response.status == 200) {
        let successMessage = '';
        successMessage = 'Instructions to reset the password were sent to ' + this.userEmailId + '.';
        this.toastService.showSuccess(successMessage);
      } else {
        this.toastService.showError('Reset password email sent fail');
      }
      this.showResetPasswordDialog = false;
      this.loadingPage = false;
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  saveAdditionalDetails() {
    let data = {
      tenantUserId: this.tenantUserId,
      emailSignatureHTML: this.emailSignatureHtml,
      marketingMode: this.isTestMode,
      marketingPreference: this.isMarketingPreference
    }
    try {
      this.billingService.saveAdditionalDetails(data).subscribe((res) => {
        if (res.status == 200) {
          console.log('Additional information changed successfully');
        } else {
          this.toastService.showError('Additional information cannot be saved, this is due to error: ' + res.message);
        }
      },
        (err) => {
          this.toastService.showError('Additional information cannot be saved, please contact your administrator for assistance');
          this.isShowSubscription = false;
        });

      return true;
    }
    catch (error) {
      this.errors.push(error);
      return false;
    }
  }

  getAdditionalDetails() {
    if (this.tenantUserId) {
      this.billingService.GetAdditionalDetails(this.tenantUserId).subscribe(
        (res) => {
          if (res.data) {
            this.emailSignatureHtml = res.data.emailSignatureHTML;
            this.isTestMode = res.data.marketingMode;
            this.isMarketingPreference = res.data.marketingPreference;
          }
        },
        (err) => {
        }
      );
    }
  }

  manageSubscription() {
    this.billingService.GetGetStripeConfigDetail().subscribe(
    (response) => {
      if (response.data) {
        var customerNavigateUrl = response.data.customerNavigateUrl;
        //var url = 'https://billing.stripe.com/p/login/test_7sIcNRdh2eb9000aEE';
        window.open(customerNavigateUrl, "_blank");
      }
    });
  }

  onCancel() {
    window.location.reload();
  }
}
